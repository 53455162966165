@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
}

/* Fix the quill drop down */
#ql-picker-options-0 {
  z-index: 1000;
}

.myPostWidgetQuill .ql-container {
  border: none !important;
}

.myPostWidgetQuill .ql-editor::before {
  color: var(--placeholder-color) !important;
}

.mention {
  color: "blue";
}

.status-pending {
  background-color: rgba(255, 0, 0, 0.5); /* Red with opacity */
  font-weight: semi-bold;
}
.status-reviewed {
  background-color: rgba(255, 165, 0, 0.5); /* Orange with opacity */
  font-weight: semi-bold;
}
.status-resolved {
  background-color: rgba(0, 128, 0, 0.5); /* Green with opacity */
  font-weight: semi-bold;
}
.status-deleted {
  background-color: rgba(0, 0, 0, 0.75); /* Black with opacity */
  color: white;
  font-weight: bold;
}
.status-edited {
  background-color: rgba(0, 0, 255, 0.5); /* Blue with opacity */
  color: white;
  font-weight: bold;
}
.status-ignored {
  background-color: rgba(128, 128, 128, 0.5); /* Gray with opacity */
  color: white;
  font-weight: bold;
}
